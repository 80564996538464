import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "../Main";
import ReportPage from "../reportPage";

const Routers = () => {
  return (
    <Routes>
      <Route exact path="/:id" element={<Main />} />
      <Route exact path="/relationalapireport/environmentId/:env_id?/apiReportId/:report_id?" element={<ReportPage />} />
    </Routes>
  );
};

export default Routers;

