import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { getBezierPath } from "reactflow";
import CustomModal from "./CustomModal";

const CustomEdge = ({ id, source, target, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data,
  relationVal, setRelationValue, setIsDialogOpen}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentConnection, setCurrentConnection] = useState({
    sourceId: null,
    targetId: null,
  });

  useEffect(() => {
    if (source && target) {
      setCurrentConnection({
        sourceId: source,
        targetId: target,
      });
    }
  }, [source, target]);

  const [pathString] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const edgeCenterX = (sourceX + targetX) / 2;
  const edgeCenterY = (sourceY + targetY) / 2;

  const handleCircleClick = () => {
    console.log('hhhiihih');
    
    if (!showModal) {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <path
        id={id}
        style={{ ...style, pointerEvents: "none" }}
        className="react-flow__edge-path"
        d={pathString}
        markerEnd={markerEnd}
      />
      <circle
        cx={edgeCenterX}
        cy={edgeCenterY}
        r={3}
        fill="#0583F2"
        style={{ pointerEvents: "all", cursor: "pointer" }}
        onClick={handleCircleClick}
      />
      {showModal && (
        <CustomModal
          isOpen={showModal}
          onClose={handleModalClose}
          data={data}
          sourceId={currentConnection.sourceId}
          targetId={currentConnection.targetId}
          className="relative"
          relationVal={relationVal}
          setRelationValue={setRelationValue}
          setShowModal={setShowModal}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
    </>
  );
};

CustomEdge.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  style: PropTypes.object,
  markerEnd: PropTypes.string,
  data: PropTypes.object,

};

export default CustomEdge;
