import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BASE_URL, ENV_ID } from "./ApiHelper.js/GetApi";
import CustomDialog from "./Utils/ModalDialog";
import ExistAPIModal from "./Utils/ExistAPIModal";
import { danglingNodeChecker } from "./Utils/DanglingNodeChecker";
import { ERROR_MESSAGES } from '../constants/errors';
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import useFetchSavedApis from "../customhooks/useFetchSavedApis";
import { validateApiNameandLimit } from "../constants/validator";

const AllDataSave = ({ onClose, nodeList, edgeList, onSaveSuccess, graphName, methodStatus, relationVal }) => {
  const { savedApis } = useFetchSavedApis();
  const [open, setOpen] = useState(true);
  const [apiName, setApiName] = useState(graphName || "");
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const [apiExistModal, setApiExistModal] = useState(false)
  const [error, setError] = useState("");
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const isValidInput = () => {
    if (!validateApiNameandLimit(apiName)) {
      setError(ERROR_MESSAGES.VALIDATION_ERROR);
      return false;
    }
    // if (!Array.isArray(relationVal) || relationVal.length === 0) {
    //   setError(ERROR_MESSAGES.CONNECT_API);
    //   return false;
    // }
    if ( nodeList.length === 1) {
      setError(ERROR_MESSAGES.CONNECT_API);
      return false;
    }
    if (apiExistModal === false && methodStatus === 'add') {
      const nameExist = savedApis.includes(apiName);
      if (nameExist) {
        setApiExistModal(true);
        return false;
      }
    }
    const isDangling = danglingNodeChecker(nodeList, edgeList);
    if (isDangling) {
      setError(ERROR_MESSAGES.CONNECT_OR_REMOVE);
      return false;
    }
    return true;
  };

  const createNodes = (relationVal) => {
    const nodes = {};
    relationVal.forEach((item) => {
      const uuid = Object.keys(item)[0];
      const data = item[uuid];
      if (!nodes[uuid]) {
        nodes[uuid] = { dependencies: [] };
      }
      data.dependencies.forEach((dep) => {
        if (!nodes[dep._id]) {
          nodes[dep._id] = { dependencies: [] };
        }
        const newDependency = {
          _id: dep._id,
          request_fields: dep.request_fields && Object.keys(dep.request_fields).length === 0 ? null : dep.request_fields,
          response_fields: dep.response_fields && Object.keys(dep.response_fields).length === 0 ? null : dep.response_fields,
        };
        nodes[uuid].dependencies.push(newDependency);
      });
    });
    return nodes;
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave(event);
    }
  };

  const saveData = async (requestData) => {
    const response = await axios.post(`${BASE_URL}/rapis/${ENV_ID}`, requestData);
    if (response.status !== 200) {
      throw new Error('Failed to save data');
    }
    return response;
  };

  const handleError = (error) => {
    if (!error.response) {
      setError(ERROR_MESSAGES.SERVER_ERROR);
      return;
    }
    const statusCode = error.response.status;
    const message = error.response.data || "An error occurred";
    const errorMessages = {
      404: `Not found: ${message}`,
      500: `Server error: ${message}`,
    };
    setError(errorMessages[statusCode] || `Error: ${message}`);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (!isValidInput()) return;
    const nodes = createNodes(relationVal);
    const requestData = {
      graphData: {
        name: apiName,
        methodstatus: methodStatus,
        oldName: graphName,
        nodes,
        graph: {
          nodeList,
          edgeList,
          contextData: relationVal,
        },
      },
    };

    try {
      const response = await saveData(requestData);
      onSaveSuccess(response.data);
      setOpen(false);
      setModelDialogOpen(true);
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length > 15) {
      setError(ERROR_MESSAGES.CHARACTER_EXCEED);
      return;
    }
    const errorMessage = validateApiNameandLimit(value) ? "" : ERROR_MESSAGES.ALPHANUMERIC_WITHOUT_SPACE;
    setError(errorMessage);
    setApiName(value);
  };
  
  const handleReplace = (e) => {
    handleSave(e)
    setModelDialogOpen(false);
    setApiExistModal(false);
  };

  const handleCancel = () => {
    setApiExistModal(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={handleClose}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              {nodeList.length > 0 ? (<form onSubmit={handleSave}>
                <div>
                  <h1 className="text-lg text-gray-700 font-semibold">
                    Save as
                  </h1>
                  <p className="text-base text-gray-700 mb-3">
                    Enter a name for this API
                  </p>
                  <div>
                    <input
                      type="text"
                      id="api_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:border-complementary focus:bg-white"
                      placeholder="Relative API"
                      value={apiName}
                      onChange={handleInputChange}
                      autoComplete="off"
                      onKeyDown={handleKeyDown}
                    />
                    {error && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="api_name_error"
                      >
                        {error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-primary hover:bg-blue-900 px-5 py-2 text-sm text-white shadow-sm sm:ml-3 sm:w-auto"
                  >Save</button>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-5 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </form>) : (

                <>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-yellow-600"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Please Add API's
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You need to add API's before saving.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="inline-flex w-full justify-center rounded-md bg-white px-5 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:w-auto"
                    > Close
                    </button>
                  </div>
                </>
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <CustomDialog
        open={modelDialogOpen}
        onClose={() => setModelDialogOpen(false)}
        title="Success"
        message="Data saved successfully!"
        buttonText="Close"
        onButtonClick={() => setModelDialogOpen(false)}
      />
      <ExistAPIModal
        isOpen={apiExistModal}
        onClose={() => setApiExistModal(false)}
        onReplace={handleReplace}
        onCancel={handleCancel}
      />
    </>
  );
};

export default AllDataSave;
