import React, {createContext, useContext, useState} from "react"
import ReactDOM from "react-dom";

const ToastContext = createContext();

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};


export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const toastTypes = {
        success: {
            classes: "bg-green-100 text-green-500",
            iconBgColor: "bg-green-200",
            toastIcon: (
                <svg
                    className="w-5 h-5 mr-2"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
            ),
        },
        information: {
            classes: "bg-blue-100 text-blue-500",
            iconBgColor: "bg-blue-200",
            toastIcon: (
                <svg
                    className="w-5 h-5 mr-2"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M12 20h.01M12 12h.01M12 8h.01"
                    />
                </svg>
            ),
        },
        warning: {
            classes: "bg-yellow-100 text-yellow-500",
            iconBgColor: "bg-yellow-200",
            toastIcon: (
                <svg
                    className="w-5 h-5 mr-2"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                </svg>
            ),
        },
        error: {
            classes: "bg-red-100 text-red-500",
            iconBgColor: "bg-red-200",
            toastIcon: (
                <svg
                    className="w-5 h-5 mr-2"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M12 4a8 8 0 110 16 8 8 0 010-16z"
                    />
                </svg>
            ),
        },
    };

    const showToast = (message, type = "success") => {
        if (toasts.some((toast) => toast.message === message)) {
            return;
        }
        const id = Math.random().toString(36).substr(2, 9);
        const toastInfo = toastTypes[type];
        setToasts((prev) => [...prev, { id, message, ...toastInfo }]);
        setTimeout(() => {
            setToasts((prev) => prev.filter((toast) => toast.id !== id));
        }, 5000);
    };

  

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {ReactDOM.createPortal(
                <div className="fixed top-3 right-8 z-50 space-y-4">
                    {toasts.map((toast) => (
                        <div
                            key={toast.id}
                            className={`toast flex items-center w-auto p-4 text-sm font-normal rounded-lg shadow-sm ${toast.classes}`}
                        >
                            <div
                                className={`inline-flex items-center justify-center w-auto ps-2 h-8 rounded-lg ${toast.iconBgColor}`}
                            >
                                {toast.toastIcon}
                            </div>
                            <div className="ml-3">{toast.message}</div>
                        </div>
                    ))}
                </div>,
                document.body
            )}
        </ToastContext.Provider>
    );
};
