import React, { useEffect } from "react";
import ReactFlow, {
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Background,
} from "reactflow";
import "reactflow/dist/style.css";
import { relationalAPIReport } from "./components/ApiHelper.js/GetApi.js";
import { useParams } from "react-router-dom";

function ReportPage() {
  const { env_id, report_id } = useParams();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  useEffect(() => {
    handleToggleOptions();
  }, []);

  const handleToggleOptions = async () => {
    const relationalAPIObject = await relationalAPIReport( env_id, report_id );
 
    const updatedNodes =
      relationalAPIObject.graph?.nodeList.map((node) => ({ ...node })) || [];
    setNodes(updatedNodes);
    setEdges(relationalAPIObject.graph?.edgeList || []);
  };

  return (
    <div className="w-full h-screen bg-gray-100">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
      >
        <Background />
      </ReactFlow>
    </div>
  );
}

export default ReportPage;
