import axios from "axios";
import { getIdFromPathname } from "../Utils/urlUtils";
import getPositions from "./PossitionHelper";
import { getNodeStyle } from "../Utils/NodeColorUtils";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const id = getIdFromPathname();
export const ENV_ID = id;

export const fetchInitialNodes = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/apis/${ENV_ID}`);
    return response.data;
  } catch (error) {
    console.error("Error details:", error.toJSON ? error.toJSON() : error);
    return [];
  }
};

export const getGraphDataByName = async (graphName) => {
  try {
    const response = await fetch(
      `${BASE_URL}/rapis/byname/${ENV_ID}/${graphName}`
    );
    const data = await response.json();
    const nodes = [];
    const edges = [];
    const contextData = [];
    const graph = [];
    const map = new Map(Object.entries(data.nodes));
    for (let [key, value] of map.entries()) {
      graph[key] = value.dependencies.map((dep) => dep._id);
    }
    const positions = getPositions(graph);
    for (let [key, value] of map.entries()) {
      const { _id, name, dependencies } = value;
      nodes.push({
        id: _id,
        data: { label: name },
        position: positions[_id],
        style: getNodeStyle(map.get(_id).request.method),
        label: name,
      });
      if (dependencies.length > 0) {
        dependencies.forEach((dep) => {
          dep.response_fields = dep.response_fields || {};
          dep.request_fields = dep.request_fields || {};
          edges.push({
            type: "custom",
            animated: true,
            target: key,
            source: dep._id,
          });
          let obj = { dependencies: [dep] };
          contextData.push({
            [key]: obj,
          });
        });
      } else {
        let obj = { dependencies: [] };
        contextData.push({
          [key]: obj,
        });
      }
    }
    localStorage.setItem("viewData", JSON.stringify(contextData));
    return { nodes, edges, contextData };
  } catch (error) {
    console.error("Error fetching graph data:", error);
    return null;
  }
};

export const searchByQueryAPI = async (searchQuery) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/apis/search?query=${searchQuery}&envId=${ENV_ID}`
    );
    return response.data;
  } catch (error) {
    console.error("Error occurred while fetching search results:", error);
    return [];
  }
};

export const getRelationalAPIByGraphNameAndEnvId = async (graphName, envId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/rapis/byname/${envId}/${graphName}`
    );
    return response.data;
  } catch (error) {
    console.error(error)
  }
};

export const fetchIndividualData = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/apis/${ENV_ID}/api/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data for ID: ${id}`, error);
      return null;
    }
  };

  export const renameRelationalAPI = async (editingId, Name) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.put(
        `https://qaapi.testserv.ai/api/PostmanTestRunner/UpdateApiName?testEnvironmentId=${ENV_ID}&apiId=${editingId}`,
        JSON.stringify(Name), config
      );
      return response.data; 
    } catch (error) {
      console.error('Error renaming API:', error); 
      throw error; 
    }
  };

  export const relationalAPIReport = async (ENV_ID, rep_id) => {
    try {
      const response = await axios.get(`${BASE_URL}/rapis/relationalGraphReport/${ENV_ID}/${rep_id}`);
      return response.data; 
    } catch (error) {
      console.error('Error renaming API:', error.message); 
      throw error; 
    }
  };
