import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import "./App.css";
import Routers from "./routes/index";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "./components/ToastContext/ToastProvider";

const App = () => {
  return (
    <>
      <ToastProvider>
        <Router>
          <Routers />
        </Router>
      </ToastProvider>
    </>
  );
};

export default App;
