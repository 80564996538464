import { useCallback } from "react";
import { addEdge } from "reactflow";
import { getSpecificPath, getTruncatedPath, splitOnUpperCase } from "../components/Utils/CommonFunctions";
import { getNodeStyle } from "../components/Utils/NodeColorUtils";

export const useNodeManagement = (items, edges, setEdges, setNodes, setSelectedNodes, setDeletedNodeId) => {
  const handleNodeSelectChange = useCallback((sectionId, nodeId, optionLabel, nodeName, isAddAction) => {
      const selectedOption = { value: nodeId, label: optionLabel };
      setSelectedNodes((prevSelectedNodes) => {
        if (isAddAction) {
          return [...prevSelectedNodes, selectedOption];
        } else {
          return prevSelectedNodes.filter((option) => option.value !== nodeId);
        }
      });

      setNodes((prevNodes) => {
        if (isAddAction) {
          const node = items.find((node) => node._id === nodeId);

          if (node) {
            const method = node.request ? node.request.method : "";
            const pathArray = node.request?.url?.path || [];
            const specificPath = getSpecificPath(pathArray);
            const truncatedPath = getTruncatedPath(pathArray);
            return [
              ...prevNodes, {
                id: nodeId,
                data: {
                  // label: splitOnUpperCase(truncatedPath),
                  label: nodeName.length > 15 ? nodeName.slice(0, 15) + "..." : nodeName,
                  method: method,
                },
                position: { x: 0, y: 0 },
                style: getNodeStyle(method),
                label: splitOnUpperCase(specificPath),
                // label: nodeName,
              },
            ];
          }
        } else {
          return prevNodes.filter((node) => node.id !== nodeId);
        }
      });

      if (!isAddAction) {
        setDeletedNodeId(nodeId);
        setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
      }
    },
    [items, setSelectedNodes, setNodes, setEdges, setDeletedNodeId]
  );

  const onConnect = useCallback((params) => {
  
      const existingEdge = edges.find((edge) => edge.source === params.source && edge.target === params.target);
      if (!existingEdge) {
        setEdges((eds) =>
          addEdge({ ...params, type: "custom", animated: true, data: { id: `${params.source}-${params.target}` } }, eds)
        );
      }
    },
    [edges, setEdges]
  );


  const updateNodeRelations = useCallback(
    (source, target) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === target) {
            return { ...node, parent: source };
          }
          return node;
        })
      );
    },
    [setNodes]
  );
  return { handleNodeSelectChange, onConnect, updateNodeRelations };
};
